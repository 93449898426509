.h-bg-color {
    &--red {
        background-color: $g-color-primary;
    }

    &--blue {
        background-color: $g-color-secondary;
    }

    &--green {
        background-color: $g-color-tertiary;
    }

    &--black {
        background-color: $g-color-black;
    }

    &--white {
        background-color: $g-color-white;
    }

    &--off-white {
        background-color: $g-color-off-white;
    }
}

.h-color {
    &--red {
        color: $g-color-primary;
    }

    &--blue {
        color: $g-color-secondary;
    }

    &--green {
        color: $g-color-tertiary;
    }

    &--black {
        color: $g-color-black;
    }

    &--white {
        color: $g-color-white;
    }

    &--off-white {
        color: $g-color-off-white;
    }
}

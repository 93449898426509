@mixin supports ($weight, $setting) {
    font-family: $g-font-tertiary;
    font-weight: $weight;

    @supports (font-variation-settings: normal) {
        font-family: $g-font-secondary;
        font-variation-settings: $setting;
        font-weight: normal;
    }
}

@mixin etc-font-family($weight) {
    @if ($weight == 500) {
        @include supports($weight, $g-semi-bold-narrow);
    }

    @if ($weight == 600) {
        @include supports($weight, $g-bold-condensed);
    }

    @if ($weight == 700) {
        @include supports($weight, $g-bold-narrow);
    }

    @if ($weight == 800) {
        @include supports($weight, $g-bold-narrow);
    }

    @if ($weight == 900) {
        @include supports($weight, $g-black-condensed);
    }
}

.h {
    &-rotate {
        &-clockwise {
            &-90 {
                transform: rotate(90deg);
            }

            &-180 {
                transform: rotate(180deg);
            }

            &-270 {
                transform: rotate(270deg);
            }
        }
    }
}

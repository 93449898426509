/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */
.hamburger {
    font: inherit;
    display: inline-block;
    overflow: visible;
    margin: 0;
    padding: 15px;
    cursor: pointer;
    transition-timing-function: linear;
    transition-duration: $g-speed;
    transition-property: opacity, filter;
    text-transform: none;
    color: inherit;
    border: 0;
    background-color: transparent;

    &-box {
        position: relative;
        display: inline-block;
        width: 40px;
        height: 24px;
    }

    &-inner {
        top: 50%;
        display: block;
        margin-top: -2px;
    }

    &-inner,
    &-inner:after,
    &-inner:before {
        position: absolute;
        width: 40px;
        height: 4px;
        transition-timing-function: ease;
        transition-duration: $g-speed * 2;
        transition-property: transform;
        background-color: $g-color-white;
    }

    &-inner:after,
    &-inner:before {
        display: block;
        content: '';
    }

    &-inner:before {
        top: -10px;
    }

    &-inner:after {
        bottom: -10px;
    }

    &--elastic {
        .hamburger-inner {
            top: 2px;
            transition-timing-function: cubic-bezier(.68, -.55, .265, 1.55);
            transition-duration: .275s;

            &::before {
                top: 10px;
                transition: opacity .125s ease .275s;
            }

            &::after {
                top: 20px;
                transition: transform .275s cubic-bezier(.68, -.55, .265, 1.55);
            }
        }
    }

    &--slider {
        .hamburger-inner {
            top: 2px;

            &::before {
                top: 10px;
                transition-property: transform, opacity;
                transition-timing-function: ease;
                transition-duration: $g-speed * 2;
            }

            &::after {
                top: 20px;
            }
        }
    }

    &--header {
        margin-right: -5px;
        padding-right: 0;
        padding-left: 5px;
        cursor: default;
        vertical-align: middle;

        &:hover {
            opacity: 1;
        }

        .hamburger-inner,
        .hamburger-inner::after,
        .hamburger-inner::before {
            background-color: $g-color-black;
        }

        &.hamburger--elastic .hamburger-inner {
            top: 2px;
        }
    }

    &-wrap {
        text-align: center;
    }

    &--accessible {
        display: inline-block;

        .hamburger-box {
            display: inline-block;
            vertical-align: middle;
        }

        .hamburger-inner,
        .hamburger--accessible .hamburger-inner:after,
        .hamburger--accessible .hamburger-inner:before {
            background-color: $g-color-black;
        }
    }

    &-label {
        font-weight: 600;
        display: inline-block;
        margin-left: 5px;
        vertical-align: middle;
        text-transform: uppercase;

        &-hidden {
            display: none;
        }
    }
}

.menu-active {
    .hamburger .hamburger-inner,
    .hamburger .hamburger-inner:after,
    .hamburger .hamburger-inner:before {
        background-color: $g-color-white;
    }

    // Elastic
    .hamburger--elastic {
        .hamburger-inner {
            transition-delay: 75ms;
            transform: translate3d(0, 10px, 0) rotate(135deg);
        }

        .hamburger-inner:before {
            transition-delay: 0s;
            opacity: 0;
        }

        .hamburger-inner:after {
            transition-delay: 75ms;
            transform: translate3d(0, -20px, 0) rotate(-270deg);
        }
    }

    // Slider
    .hamburger--slider {
        .hamburger-inner {
            transform: translate3d(0, 10px, 0) rotate(45deg);

            &::before {
                transform: rotate(-45deg) translate3d(-5.71429px, -6px, 0);
                opacity: 0;
            }

            &::after {
                transform: translate3d(0, -20px, 0) rotate(-90deg);
            }
        }
    }
}


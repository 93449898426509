.h-icon {
    vertical-align: -.15em;
    width: 2em;
    height: 2em;
    overflow: hidden;
    color: inherit;
    fill: currentColor;
}

.h-logo {
    max-height: 5em;
    margin: bu(2) 0;

    &--landscape {
        max-height: 5rem;
    }

    &--letterbox {
    	max-height: 5rem;
    }
}

@mixin hero-bg($image) {
    // From 0px to 511px
    background-image: url("../images/hero/#{$image}/#{$image}-x-small.jpg");

    @include retina {
        background-image: url("../images/hero/#{$image}/#{$image}-x-small@2x.jpg");
    }

    @include media($g-bp-x-small-and-up) {
        // From 512px to 623px
        background-image: url("../images/hero/#{$image}/#{$image}-small.jpg");

        @include retina {
            background-image: url("../images/hero/#{$image}/#{$image}-small@2x.jpg");
        }
    }

    @include media($g-bp-small-and-up) {
        // From 624px to 767px
        background-image: url("../images/hero/#{$image}/#{$image}-medium.jpg");

        @include retina {
            background-image: url("../images/hero/#{$image}/#{$image}-medium@2x.jpg");
        }
    }

    @include media($g-bp-medium-and-up) {
        // From 768px to 935px
        background-image: url("../images/hero/#{$image}/#{$image}-medium-large.jpg");

        @include retina {
            background-image: url("../images/hero/#{$image}/#{$image}-medium-large@2x.jpg");
        }
    }

    @include media($g-bp-medium-large-and-up) {
        // From 936px to 959px
        background-image: url("../images/hero/#{$image}/#{$image}-large.jpg");

        @include retina {
            background-image: url("../images/hero/#{$image}/#{$image}-large@2x.jpg");
        }
    }

    @include media($g-bp-large-and-up) {
        // From 960px to 1359px
        background-image: url("../images/hero/#{$image}/#{$image}-x-large.jpg");

        @include retina {
            background-image: url("../images/hero/#{$image}/#{$image}-x-large@2x.jpg");
        }
    }

    @include media($g-bp-x-large-and-up) {
        // From 1360px and up
        background-image: url("../images/hero/#{$image}/#{$image}-xx-large.jpg");

        @include retina {
            background-image: url("../images/hero/#{$image}/#{$image}-xx-large@2x.jpg");
        }
    }
}

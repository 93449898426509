// State helpers
%h-focusable {
    &:focus {
        .js-enabled & {
            outline: 0;
        }

        .js-wai-outline & {
            outline: 2px solid $g-color-focus-outline;
            outline-offset: 2px;
        }
    }
}

:focus {
    .js-enabled & {
        outline: 0;
    }
}

.h-no-js-hide {
    .js-disabled & {
        display: none !important;
    }
}

.h-js-hide {
    .js-enabled & {
        display: none;
    }
}

.h-hidden {
    display: none !important;
}

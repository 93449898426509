body {
    background-color: $g-color-white;
    color: $g-color-black;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    text-rendering: auto;
}

body,
input,
select,
button,
textarea {
    font-family: $g-font-primary;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $g-font-primary;
    color: $g-color-black;
    display: inline-block;
    font-weight: normal;

    + p {
        margin-top: 0;
    }
}

h2,
h3,
h4,
h5,
h6 {
letter-spacing: -.02em;
}


// Mobile First Styles
h1 {
    @include font-scale(7);

    @include media($g-bp-medium-and-up) {
        @include font-scale(14);
    }
}

h2 {
    @include font-scale(6);

    @include media($g-bp-medium-and-up) {
        @include font-scale(12);
    }
}

h3 {
    @include font-scale(4);

    @include media($g-bp-medium-and-up) {
        @include font-scale(8);
    }
}

h4 {
    @include font-scale(3);
    margin: bu(3) 0 bu(1);
    line-height: bu(4);
    font-weight: 500;
}

h5 {
    @include font-scale(1);

    @include media($g-bp-medium-and-up) {
        @include font-scale(2);
    }
}

h6 {
    @include font-scale(0);

    @include media($g-bp-medium-and-up) {
        @include font-scale(1);
    }
}

%h1 {
    @extend h1;
}

%h2 {
    @extend h2;
}

%h3 {
    @extend h3;
}

%h4 {
    @extend h4;
}

%h5 {
    @extend h5;
}

%h6 {
    @extend h6;
}

p,
li {
    margin-bottom: bu(2);
    letter-spacing: -0.5px;
}

%p {
    @extend p;
}

dl {
    color: $g-color-black;
}

td {
    color: $g-color-black;
}

a {
    border-bottom: 1px solid $g-color-red;
    padding-bottom: 1px;
    color: $g-color-black;
    text-decoration: none;

    &:hover {
        color: $g-color-red;
    }
}

hr {
    display: block;
    height: 1px;
    padding: 0;
    border: 0;
    border-top: 1px solid $g-color-black;
    margin: bu(5) 0;
}

a,
button,
input,
textarea,
select,
[tabIndex*=''] {
    @extend %h-focusable;
}

blockquote {
    display: inline-block;
    padding: 0;
    margin-bottom: bu(8);

    cite {
        @include font-scale(1);
        font-weight: 100;
        font-style: normal;
    }
}

mark { // oh hi mark
    background-color: $g-color-black;
    color: $g-color-primary;
}

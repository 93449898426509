@function strip-units($value) {
    @return ($value / ($value * 0 + 1));
}

@function rem($pxval) {
    @if not unitless($pxval) {
        $pxval: strip-units($pxval);
    }

    $base: $g-font-base;

    @if not unitless($base) {
        $base: strip-units($base);
    }

    @return ($pxval / $base) * 1rem;
}

//@import 'node_modules/normalize-scss/sass/normalize/import-now';

@import
'tools',
'variables',
'layout',
'helpers',
'lib',
'base',
'animation',
'components';

.b-404,
%b-404 {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;

    &__title {
        @include etc-font-family(900);
        margin-top: bu(11);
        margin-bottom: bu(5);
        font-size: ms(13);
        line-height: bu(7.5);
        text-align: center;
        text-transform: uppercase;

        @include media($g-bp-large-and-up) {
            margin-top: bu(15);
            font-size: ms(19);
            line-height: bu(15);
            font-variation-settings: $g-bold-narrow;
        }
    }

    &__content {
        margin-bottom: bu(5);
        font-size: ms(1);
        line-height: bu(3.5);

        @include media($g-bp-large-and-up) {
            font-size: ms(2);
        }
    }

    &__links {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        padding: bu(1.5) bu(6);
        border: 4px solid $g-color-black;
        margin-bottom: bu(5);
        text-transform: uppercase;

        &__item {
            @include etc-font-family(800);
            padding: 0;
            margin: bu(1.5) 0;
            font-size: ms(4);
            text-transform: uppercase;

            @include media($g-bp-large-and-up) {
                margin: 0;
                font-size: ms(5);
            }

            &:hover {
                color: $g-color-white;
            }
        }

        &__separator {
            display: none;
            height: bu(3);
            margin: 0 bu(3);

            @include media($g-bp-large-and-up) {
                display: inline-block;
            }
        }

        @include media($g-bp-large-and-up) {
            flex-direction: row;
            width: auto;
            margin-bottom: bu(15);
        }
    }
}

.b-bar-graph,
%b-bar-graph {
    &__label {
        font-weight: bold;
        font-size: ms(1) !important;
        margin-bottom: bu(1) !important;

        @include media($g-bp-medium-and-up) {
            font-size: ms(2) !important;
            margin-bottom: bu(2) !important;
        }
    }

    &__bar {
        width: 100%;
        display: flex;
        flex-direction: row;
        margin-bottom: bu(2);

        &__percent {
            height: bu(4);
            line-height: bu(4);
            font-size: ms(3);
            text-align: center;

            @include media($g-bp-medium-and-up) {
                height: bu(6);
                line-height: bu(6);
                font-size: ms(4);
            }
        }

        &__remainder {
            height: bu(4);
            border: 4px solid #DEDFDC;
            border-left: none;
            flex-grow: 100;

            @include media($g-bp-medium-and-up) {
                height: bu(6);
            }
        }

        @include media($g-bp-medium-and-up) {
            margin-bottom: bu(4);
        }
    }

    &--percent-zero {
        // Add the left border back in.
        .b-bar-graph__bar__remainder{
            border-left: 4px solid #DEDFDC;
        }
    }

    &--percent-100 {
        // Remove the remainder so that no border is shown.
        .b-bar-graph__bar__remainder{
            display: none;
        }
    }
}

.bg-hero {
    position: relative;
    background: $g-color-white url('../images/hero/bg-hero.jpg') center;
    min-height: 940px;
    display: flex;
    width: 100%;
}

.b-button,
%b-button {
    @include etc-font-family(700);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: bu(1.5, -2) bu(3);
    border: 2px solid $g-color-black;
    font-size: ms(3);
    line-height: 1;
    color: $g-color-black;
    text-transform: uppercase;
    cursor: pointer;

    &__content {
        margin-top: rem(2);

        &__icon {
            width: 1.5em;
            height: 1em;
            margin-left: bu(2);
        }
    }

    &:hover {
        background-color: $g-color-black;
        color: $g-color-white;
    }

    &--black {
        background-color: $g-color-black;
        color: $g-color-white;

        &:hover {
            background-color: $g-color-white;
            color: $g-color-black;
        }
    }

    @include media($g-bp-large-and-up) {
        font-size: ms(4);
        padding: bu(2, -3) bu(2.5);
        border-width: 3px;

        &__content {
            &__icon {
                width: 1.8em;
                margin-left: bu(3);
            }
        }
    }
}


.b-calculator,
%b-calculator {

    &__diamond {
        width: 100%;
        margin-top: -80px;
        height: bu(5);
    }

    &__input {
        padding: 0 0 bu(4.5);
        background-color: $g-color-red;
        font-size: ms(2);
        line-height: 1;
        letter-spacing: -.056em;

        &__number {
            margin-bottom: bu(4.5);
            border-bottom: 1px solid $g-color-black;
            padding-bottom: bu(2.5);

            &__amount {
                color: $g-color-white;
                font-weight: 600;
            }

            &__range {
                margin-top: bu(1);
            }
        }

        &__type {

            &__option {
                &-wrap {
                    position: relative;
                    margin-right: bu(4);

                    &:last-child {
                        margin-right: 0;
                    }
                }

                + label {
                    padding-left: bu(4);
                    cursor: pointer;

                    &::before,
                    &::after {
                        content: '';
                        display: block;
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        margin: auto;
                        border-radius: 50%;
                    }

                    &:after {
                        display: none;
                        width: rem(14);
                        height: rem(14);
                        left: rem(6);
                        background: $g-color-black;
                    }

                    &::before {
                        border: 4px solid $g-color-black;
                        left: 0;
                        width: bu(2.5, -2);
                        height: bu(2.5, -2);
                    }
                }

                &:checked {
                    + label {
                        &:after {
                            display: block;
                        }
                    }
                }
            }

            &__options {
                display: flex;
                margin-top: bu(4);
                font-size: ms(0);
                color: $g-color-white;
                font-weight: 700;
                letter-spacing: -.047em;
            }
        }
    }

    @include media($g-bp-medium-and-up) {
        &__input {
            font-size: ms(3);
        }
    }

    @include media($g-bp-large-and-up) {
        &__diamond {
            display: none;
        }

        &__input {
            @include grid-row();
            padding: bu(5) 0 bu(8);
            border-top: 4px solid $g-color-black;
            font-size: ms(3);

            &__number {
                border: 0;
                padding: 0;
                margin: 0;

                &__range {
                    margin-top: bu(4);
                }
            }

            > * {
                @include span-column(6/12, 50px);
                margin-bottom: 0 !important;
            }

            &__type__option {
                &-wrap {
                    margin-right: bu(2.5);
                }

                + label {
                    padding-left: bu(6);
                    margin: 0;
                    font-size: ms(3);

                    &:after {
                        width: rem(18);
                        height: rem(18);
                        left: rem(8);
                    }

                    &::before {
                        width: bu(3.5, -2);
                        height: bu(3.5, -2);
                    }
                }
            }
        }
    }

    @include media($g-bp-x-large-and-up) {
        &__input {
            font-size: ms(5);

            &__type__option {
                &-wrap {
                    margin-right: bu(2.5);
                }

                + label {
                    font-size: ms(4);
                }
            }
        }
    }

    @media screen and (min-width: 1180px) {
        &__input {
            > * {
                @include span-column(5/12);
            }

            &__type__options {
                justify-content: space-between;
            }
        }
    }

    &__numbers {
        display: grid;
        margin-top: bu(6);
        grid-template-columns: 1fr;
        column-gap: bu(15);

        &__switching {
            margin-bottom: bu(6);
            text-align: center;

            &__title {
                @include etc-font-family(800);
                font-size: ms(4);
                color: $g-color-black;
                margin-top: 0;
                text-transform: uppercase;

                @include media($g-bp-medium-and-up) {
                    font-size: ms(6);
                }

                @include media($g-bp-large-and-up) {
                    font-size: ms(10);
                    margin-top: bu(7.5);
                }
            }

            &__amount {
                @include etc-font-family(900);
                font-size: ms(18);
                line-height: bu(17);

                @include media($g-bp-medium-and-up) {
                    font-size: 24.66385rem;
                    line-height: bu(22);
                }

                @include media($g-bp-large-and-up) {
                    font-size: ms(24);
                    line-height: bu(37);
                }
            }

            &__period {
                @include etc-font-family(800);
                margin-top: bu(1);
                margin-bottom: bu(3);
                font-size: ms(4);
                text-transform: uppercase;

                @include media($g-bp-medium-and-up) {
                    font-size: ms(6);
                    margin-bottom: bu(1);
                }

                @include media($g-bp-large-and-up) {
                    margin-top: -20px;
                    font-size: ms(7);
                    margin-bottom: bu(1.5);
                }
            }

            &__year {
                margin-bottom: bu(3);
                font-size: ms(1);

                &__total {
                    font-weight: bold;
                }

                @include media($g-bp-large-and-up) {
                    font-size: ms(3);
                }
            }

            @include media($g-bp-large-and-up) {
                margin-bottom: 0;
                border-bottom: 4px solid $g-color-black;
                border-top: 4px solid $g-color-black;
            }
        }

        &__share {
            @include etc-font-family(700);
            width: 100%;
            display: flex;
            align-items: center;
            flex-direction: row;
            justify-content: center;
            padding: bu(2);
            border: 4px solid $g-color-black;
            margin-top: bu(5);
            font-size: ms(4);
            color: $g-color-white;
            background-color: $g-color-black;
            text-transform: uppercase;
            cursor: pointer;
            line-height: bu(3.5);

            svg {
                height: 1em;
                width: 1em;
                margin-right: bu(3);

                @include media($g-bp-large-and-up) {
                    margin-right: bu(2.5);
                }
            }

            &__platforms {
                width: 100%;
                display: flex;
                align-items: center;
                flex-direction: row;
                justify-content: space-evenly;
                padding: bu(1.2);
                margin-top: bu(5);
                border: 4px solid $g-color-black;
                background-color: $g-color-black;
                color: $g-color-white;
                margin-bottom: bu(2.5);

                &__icon {
                    width: bu(5);
                    height: bu(5);
                }

                a {
                    color: $g-color-white;
                    text-decoration: none;
                    padding: 0;
                    border: none;

                    &:hover {
                        color: $g-color-red;
                    }
                }

                @include media($g-bp-large-and-up) {
                    padding: bu(2.35) 0;
                    margin-bottom: 0;
                }
            }

            @include media($g-bp-large-and-up) {
                font-size: ms(5);
                padding: bu(3);
            }

            // Div for mobile with the share button and platform icons.
            &__mobile {
                @include media($g-bp-large-and-up) {
                    display: none;
                }
            }

            // Div for desktop with the share button and platform icons.
            &__desktop {
                display: none;

                @include media($g-bp-large-and-up) {
                    display: block;
                    grid-column-end: 3;
                    grid-column-start: 1;
                }
            }

            // The share button specifically on mobile
            &--mobile {
                margin-bottom: bu(2.5);
            }

            &:hover {
                color: $g-color-black;
                background-color: $g-color-white;
            }
        }

        &__comparison {
            display: grid;
            grid-template-columns: 1fr 1fr;
            column-gap: bu(2);

            &__item {
                &__title {
                    @include etc-font-family(700);
                    width: 100%;
                    padding: bu(.5) bu(1);
                    background-color: $g-color-white;
                    font-size: ms(3);
                    color: $g-color-black;
                    text-transform: uppercase;
                    margin: 0;
                    line-height: bu(4);

                    @include media($g-bp-large-and-up) {
                        font-size: ms(4);
                        padding: bu(1) bu(2);
                    }
                }

                &__value {
                    padding-bottom: bu(.5);
                    border-bottom: 1px solid $g-color-black;
                    margin-top: bu(3);
                    font-size: ms(0);

                    &__amount {
                        @include etc-font-family(700);
                        margin-bottom: bu(.5);
                        font-size: ms(6);

                        @include media($g-bp-large-and-up) {
                            font-size: ms(9);
                        }
                    }

                    &__period {
                        font-weight: $g-semi-bold;
                        letter-spacing: -.05em;
                    }

                    @include media($g-bp-large-and-up) {
                        margin-top: bu(5);
                        border-bottom: 2px solid $g-color-black;
                    }
                }
            }

            @include media($g-bp-large-and-up) {
                column-gap: bu(8);
            }
        }

        @include media($g-bp-large-and-up) {
            grid-template-columns: 1fr 1fr;
        }
    }

    &__notes {
        display: grid;
        margin-top: bu(7);
        grid-template-columns: 1fr;
        column-gap: bu(15);
        font-size: ms(1);
        line-height: bu(3.5);

        &__setup {
            padding: bu(5) bu(2);
            border: 4px solid $g-color-black;
            background-color: $g-color-white;

            &__title {
                @include etc-font-family(700);
                margin-top: 0;
                margin-bottom: bu(2);
                font-size: ms(5);
                line-height: bu(4);
                text-transform: uppercase;


                @include media($g-bp-medium-and-up) {
                    font-size: ms(7);
                }
            }

            &__kit__low,
            &__kit__high,
            &__month__low,
            &__month__high,
            &__month__ongoing {
                font-weight: $g-semi-bold;
            }

            @include media($g-bp-medium-and-up) {
                padding: bu(5) bu(4);
            }
        }

        &__numbers {
            padding: bu(5) bu(2);
            border: 4px solid $g-color-black;
            margin-bottom: bu(3);
            background-color: $g-color-white;
            grid-row-start: 1;
            grid-row-end: 2;

            &__arrow {
                display: none;

                // This causes a weird issue if revealed sooner - pushes the title in the box down.
                @include media($g-bp-x-large-and-up) {
                    display: inline;
                    position: relative;
                    top: -74px;
                    left: 47%;
                    margin-right: -40px;
                }
            }

            &__title {
                @include etc-font-family(700);
                margin: 0;
                font-size: ms(5);
                line-height: bu(4);
                text-transform: uppercase;

                @include media($g-bp-medium-and-up) {
                    font-size: ms(7);
                }
            }

            ul {
                margin-top: bu(2);
            }

            @include media($g-bp-medium-and-up) {
                padding: bu(5) bu(4);
            }

            @include media($g-bp-large-and-up) {
                margin-bottom: 0;
                grid-row-end: auto;
                grid-row-start: auto;
                grid-column-start: 2;
                grid-column-end: 3;
                padding: bu(5) bu(4);
            }
        }

        @include media($g-bp-large-and-up) {
            grid-template-columns: 1fr 1fr;
            font-size: 2.53125rem;
            line-height: bu(3.5);
        }
    }

    &__content--zero-smokes {
        text-align: center;
        margin-top: bu(3);

        &__title {
            @include etc-font-family(700);
            text-transform: uppercase;
            font-size: ms(9);
            line-height: bu(5.5);

            @include media($g-bp-large-and-up) {
                font-size: ms(15);
                line-height: bu(11);
            }
        }

        @include media($g-bp-large-and-up) {
            margin: bu(8);
        }
    }

    &__footer {
        margin: bu(3) bu(0);

        @include media($g-bp-large-and-up) {
            margin: bu(8) bu(0);
        }
    }
}

// excess height to improve interactive area / accessibility
$height: 30px;
$thumb-height: 30px;
$track-height: 8px;

// colours
$upper-color: #fff;
$lower-color: #000;
$thumb-color: #000;
$thumb-hover-color: #000;

$upper-background: linear-gradient(to bottom, $upper-color, $upper-color) 100% 50% / 100% $track-height no-repeat transparent;
$lower-background: linear-gradient(to bottom, $lower-color, $lower-color) 100% 50% / 100% $track-height no-repeat transparent;

// Webkit cannot style progress so we fake it with a long shadow
// on the thumb element
@function webkit-slider-thumb-shadow($i: 1) {
    $val: #{$i}px 0 0 -#{($thumb-height - $track-height) / 2} #{$upper-color};

    @if $i==1 {
        @for $k from 2 through 1000 {
            $val: #{$val},
            webkit-slider-thumb-shadow($k);
        }
    }

    @return $val;
}

.b-range-slider {
    display: block;
    appearance: none;
    width: 100%;
    overflow: hidden;
    cursor: pointer;
    background: transparent;

    // Webkit
    &::-webkit-slider-runnable-track {
        width: 100%;
        height: $height;
        background: $lower-background;
    }

    &::-webkit-slider-thumb {
        position: relative;
        appearance: none;
        height: $thumb-height;
        width: $thumb-height;
        background: $thumb-color;
        border-radius: 100%;
        border: 0;
        top: 50%;
        margin-top: (-$thumb-height/2);
        box-shadow: webkit-slider-thumb-shadow();
        transition: background-color 150ms;
    }

    // Firefox
    &::-moz-range-track,
    &::-moz-range-progress {
        width: 100%;
        height: $height;
        background: $upper-background;
    }

    &::-moz-range-progress {
        background: $lower-background;
    }

    &::-moz-range-thumb {
        appearance: none;
        margin: 0;
        height: $thumb-height;
        width: $thumb-height;
        background: $thumb-color;
        border-radius: 100%;
        border: 0;
        transition: background-color 150ms;
    }

    // Internet Exploder
    &::-ms-track {
        width: 100%;
        height: $height;
        border: 0;
        // color needed to hide track marks
        color: transparent;
        background: transparent;
    }

    &::-ms-fill-lower {
        background: $lower-background;
    }

    &::-ms-fill-upper {
        background: $upper-background;
    }

    &::-ms-thumb {
        appearance: none;
        height: $thumb-height;
        width: $thumb-height;
        background: $thumb-color;
        border-radius: 100%;
        border: 0;
        transition: background-color 150ms;
        // IE Edge thinks it can support -webkit prefixes
        top: 0;
        margin: 0;
        box-shadow: none;
    }

    &:focus {
        outline: none;
    }

    &:hover,
    &:focus {

        &::-webkit-slider-thumb {
            background-color: $thumb-hover-color;
        }

        &::-moz-range-thumb {
            background-color: $thumb-hover-color;
        }

        &::-ms-thumb {
            background-color: $thumb-hover-color;
        }
    }
}
.b-call-to-action,
%b-call-to-action {

    @include content-block();

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: bu(3) bu(2);
    border: 4px solid $g-color-black;

    &--red {
        background-color: $g-color-red;
    }

    &--green {
        background-color: $g-color-green;
    }

    &__title {
        font-family: $g-font-tertiary !important;
        font-weight: 700 !important;
        margin-top: 0 !important;           // Important because the content_content h3 style overrides this when the CTA is inside a content section.
        margin-bottom: 0 !important;
        font-weight: normal !important;
        font-size: ms(5) !important;
        line-height: bu(3) !important;
        color: $g-color-black;
        text-transform: uppercase;
        display: flex;
        flex-direction: column;

        @supports (font-variation-settings: normal) {
            font-family: $g-font-secondary !important;
            font-variation-settings: $g-bold-narrow !important;
            font-weight: normal !important;
        }

        @include media($g-bp-medium-and-up) {
            font-size: ms(8) !important;
            line-height: bu(4.5) !important;
        }

        @include media($g-bp-large-and-up) {
            font-size: ms(10) !important;
            line-height: bu(5.5) !important;
            padding: bu(2) 0 !important;
        }
    }

    &--fullwidth {
        margin-bottom: bu(7);

        .b-call-to-action__title {
            @include media($g-bp-x-large-and-up) {
                flex-direction: row;

                span {
                    margin-right: bu(1);
                }
            }
        }

        @include media($g-bp-large-and-up) {
            border: 6px solid $g-color-black !important;
            padding: bu(1) bu(4) !important;
            margin-bottom: bu(10);
        }
    }

    svg {
        width: 2em;
        height: 2em;

        @include media($g-bp-large-and-up) {
            width: 3.5em;
            height: 3.5em;
        }
    }

    @include media($g-bp-large-and-up) {
        padding: bu(5);
    }

    &:hover {
        background-color: $g-color-white !important;
        color: $g-color-black !important;
    }
}

// The divs with the items are used directly on the vaping-vs-smoking comparison page, hence only the scss file here
// as this saved having to have lots of macros and passing the content in to a comparison component which only used
// on the one page.

.b-comparison,
%b-comparison {
    display: grid;
    grid-template-columns: 1fr;

    // These 2 are affecting the styles applied to the graphs etc requiring !important there :(
    p {
        font-size: ms(1);
        line-height: bu(3.5);
        margin-top: 0;
        margin-bottom: bu(2);

        @include media($g-bp-medium-and-up) {
            font-size: ms(2);
            line-height: bu(4);
        }

        @include media($g-bp-large-and-up) {
            font-size: ms(2);
            line-height: bu(4);
        }
    }

    h3 {
        @include etc-font-family(700);
        text-transform: uppercase;
        font-size: ms(8);
        margin: 0;
        margin-bottom: bu(2);

        @include media($g-bp-medium-and-up) {
            font-size: ms(10);
        }
    }

    &__intro {
        @include media($g-bp-x-large-and-up) {
            margin-right: bu(6);
        }
    }

    &__graph {
        margin-bottom: bu(4);
        margin-top: bu(4);

        @include media($g-bp-large-and-up) {
            margin-top: 0;
            grid-row-start: 1;
            grid-row-end: 5;
            grid-column-start: 2;
            grid-column-end: 3;
        }

        h3 {
            margin: 0 0 bu(3);
        }
    }

    &__additional {
        a {
            border-color: $g-color-black;

            &:hover {
                background-color: $g-color-black;
                color: $g-color-white;
            }
        }

        @include media($g-bp-x-large-and-up) {
            margin-right: bu(6);
        }
    }

    &__option {
        font-weight: bold;

        &__line {
            width: 25%;
            height: 4px;
            background-color: $g-color-black;
            margin-top: bu(4);
            margin-bottom: bu(8);
        }

        @include media($g-bp-x-large-and-up) {
            margin-right: bu(6);
        }
    }

    &__fact {

        a {
            border-color: $g-color-black;

            &:hover {
                background-color: $g-color-black;
                color: $g-color-white;
            }
        }

        @include media($g-bp-x-large-and-up) {
            margin-right: bu(6);
        }
    }

    @include media($g-bp-large-and-up) {
        grid-template-columns: 1fr 1fr;
        column-gap: bu(11);
    }
}

.b-content,
%b-content {
    @include container();

    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: bu(7);

    &__title {
        @include etc-font-family(800);
        margin-top: 0;
        margin-bottom: bu(3);
        font-size: ms(9);
        color: $g-color-dark-gray;
        text-transform: uppercase;
        line-height: bu(4.5);

        @include media($g-bp-medium-and-up) {
            width: 30%;
        }

        @include media($g-bp-large-and-up) {
            font-size: ms(10);
            line-height: bu(5.5);
        }
    }

    &__content {
        width: 100%;
        margin-bottom: bu(2);

        @include media($g-bp-medium-and-up) {
            width: 70%;
            margin-left: bu(2);
        }

        @include media($g-bp-large-and-up) {
            width: 65%;
            margin-left: bu(10);
        }

        p {
            margin-top: 0;
            line-height: bu(3.5);
            font-size: ms(1);

            @include media($g-bp-large-and-up) {
                font-size: ms(2);
                line-height: bu(4);
            }
        }

        a {
            line-height: bu(3.5);
            font-size: ms(1);
            border-color: $g-color-black;
            word-wrap: break-word;

            &:hover {
                background-color: $g-color-black;
                color: $g-color-white;
            }

            @include media($g-bp-large-and-up) {
                font-size: ms(2);
                line-height: bu(4);
            }
        }

        em {
            font-style: normal;
            font-weight: $g-semi-bold;
        }

        h3 {
            font-size: ms(2);
            margin-bottom: bu(1.5);
            font-weight: bold;
            margin-top: 0;

            @include media($g-bp-medium-and-up) {
                font-size: ms(3);
            }
        }
    }

    @include media($g-bp-medium-and-up) {
        flex-direction: row;
    }

    @include media($g-bp-large-and-up) {
        margin-bottom: bu(10);
    }
}

.b-find-an-answer-modal,
%b-find-an-answer-modal {
    // Now our own styles to make it look nice
    &__overlay {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.8);
        z-index: 2;
    }

    &__dialog {
        z-index: 3;
        border: 8px solid $g-color-green;
        background-color: $g-color-white;
        position: fixed;
        margin: 0;
        top: bu(7);
        left: 0;
        width: 100%;
        height: calc(100vh - 7rem);
        padding: bu(2);
        overflow: auto;

        &__title {
            @include etc-font-family(900);
            margin: 0;
            font-size: ms(12);
            text-transform: uppercase;
            line-height: bu(7);
            max-width: 70%;

            @include media($g-bp-medium-and-up) {
                max-width: none;
                font-size: ms(14);
                line-height: bu(8.5);
            }
        }

        &__close {
            position: absolute;
            top: bu(2);
            right: bu(2);
            border: 0;
            padding: 0;
            background-color: transparent;
            width: bu(3);
            height: bu(3);
            cursor: pointer;

            svg {
                width: bu(3);
                height: bu(3);
            }

            @include media($g-bp-medium-and-up) {
                top: bu(5);
                right: bu(5);
            }
        }

        &__line {
            width: 100%;
            height: 2px;
            background-color: $g-color-black;
            margin: bu(3) 0;

            @include media($g-bp-medium-and-up) {
                margin: bu(5) 0;
            }
        }

        &__intro {
            line-height: bu(3);

            @include media($g-bp-large-and-up) {
                line-height: bu(4);
            }
        }

        &__note {
            font-size: ms(-1);
            margin-bottom: bu(3);
            line-height: bu(2);

            @include media($g-bp-medium-and-up) {
                margin-bottom: bu(4);
            }
        }

        &__email {
            font-size: ms(-1);
            line-height: bu(2);
            margin-top: bu(3);

            @include media($g-bp-medium-and-up) {
                margin-top: bu(4);
            }
        }

        @include media($g-bp-medium-and-up) {
            padding: bu(5);
            top: 50%;
            left: 50%;
            -webkit-transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
            max-width: bu(86);
            height: auto;
        }
    }
}

// Styles required by a11y dialog
[data-a11y-dialog-native] > .b-find-an-answer-modal__overlay {
    display: none;
}

dialog[open] {
    display: block;
}

.b-find-an-answer__dialog__container[aria-hidden='true'] {
    display: none;
}

.b-find-an-answer,
%b-find-an-answer {
    background-color: $g-color-white;
    padding: bu(2) 0;

    &__inner {
        @include container();
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-top: bu(2.5);
        padding-bottom: bu(2.5);

        @include media($g-bp-medium-and-up) {
            flex-direction: row;
            align-items: center;
            padding-top: bu(3.5);
            padding-bottom: bu(3.5);
        }
    }

    &__title {
        @include etc-font-family(700);
        margin-left: 0;
        font-size: ms(8);
        line-height: bu(4);
        color: $g-color-black;
        text-transform: uppercase;

        @include media($g-bp-medium-and-up) {
            font-size: ms(10);
            line-height: bu(5.5);
        }
    }

    &__buttons {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-top: bu(1);
        margin-right: 0;

        @include media($g-bp-medium-and-up) {
            margin-top: bu(4);
            flex-direction: row;
            width: auto;
            margin-top: 0;
        }
    }

    &__button {
        margin-top: bu(2);
        margin-left: 0;

        @include media($g-bp-medium-and-up) {
            margin-top: 0;
            margin-left: bu(1.5);
        }
    }
}

.b-footer {
    $logo-wrapper-width: bu(26);
    background-color: $g-color-near-black;
    color: $g-color-white;

    * {
        color: $g-color-white;
    }

    &__inner {
        @include container();
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: bu(4) bu(2);
    }

    &__sponsors {
        > p {
            font-size: ms(1);
            line-height: bu(3.5);

            &:first-child {
                margin-top: 0;
                margin-bottom: bu(3);
            }
        }

        a {
            font-weight: bold;
            border-color: $g-color-red;

            &:hover {
                background-color: $g-color-red;
                color: $g-color-white;
            }
        }
    }

    &__logo-wrapper {
        display: flex;
        flex-wrap: wrap;
        padding-bottom: bu(1);
        border-bottom: 2px solid $g-color-black;
        margin-bottom: bu(3);
    }

    &__logo {
        margin-right: bu(3);
        margin-bottom: bu(1);
        height: 80px;
    }

    // All the logos are different shapes so need to be sized individually.
    .logo--moh {
        .h-icon {
            width: bu(15);
            height: bu(10);
        }
    }

    .logo--hpa {
        .h-icon {
            width: bu(18);
            height: bu(11);
            margin-top: -8px;
        }
    }

    .logo--smokefree{
        .h-icon {
            width: bu(10);
            height: bu(8);
        }
    }

    .b-utility-links {
        &__list {
            list-style-type: none;
            padding: 0;
            margin: 0;
            display: none;

            &-item {
                margin-bottom: bu(2);

                &__link {
                    border: 0;
                    padding: 0;
                }
            }
        }
    }

    @include media($g-bp-medium-and-up) {
        &__inner {
            padding-top: bu(8);
            padding-bottom: bu(8);
            flex-direction: row;
            justify-content: space-between;
        }

        &__logo-wrapper {
            width: bu(40);
            border-bottom: none;
        }

        &__logo {
            margin-bottom: 0;
        }

        &__sponsors {
            width: bu(40);
        }

        &__links-wrapper {
            margin-left: bu(15);
            padding-right: bu(7);
        }

        .b-utility-links {
            &__list {
                display: block;
            }
        }

        .logo--smokefree{
            .h-icon {
                width: bu(13);
                height: bu(10);
            }
        }
    }
}

.b-copyright {
    background: $g-color-black;
    padding: bu(1);

    &__inner {
        @include container();
        min-width: auto;

        p {
            margin: 0;
            font-size: ms(-1);
        }
    }
}

.b-header-panel {
    overflow: hidden;

    &__inner {
        @include container();
    }

    &__logo-page {
        padding-top: bu(7.5);

        .b-logo {
            font-size: ms(9);
        }
    }

    &--bg-image {
        background-repeat: no-repeat;
        background-position: center -150px;

        .b-header-panel__inner {
            min-height: 798px;
        }
    }
}

.b-header-panel__logo-page .b-logo {
    transform: translateY(0) translateX(0) scale(1);
    transition: transform $g-speed;
}

.position-scrolled .b-header-panel__logo-page .b-logo {
    border: 1px solid red;
    transform: translateY(-200px) translateX(-10px) scale(.45);
}

.b-hero-text {
    @include etc-font-family(900);
    display: block;
    color: $g-color-black;
    text-transform: uppercase;
    font-size: ms(12);
    line-height: bu(6);
    position: absolute;
    bottom: 10%;
    transform-origin: bottom left;
    transform: scale(.8);

    &--landing {
        transform: scale(1);
    }

    span {
        position: relative;
        z-index: 2;
        background-color: $g-color-white;
        display: inline-block;
        padding: 0 bu(.5);
        white-space: nowrap;
    }

    span:first-child {
        position: static;
    }

    span:last-child {
        z-index: 1;
        top: bu(-1);
    }

    span:first-child,
    span:last-child {
        padding-top: bu(1);
        padding-bottom: bu(1);
    }

    // This includes the <br>
    span:nth-child(3) {
        padding-top: 0;
        top: bu(-.5);
    }

    em {
        font-style: normal;
        color: $g-color-red;
    }

    &--alt {
        span:first-child {
            padding-bottom: 0;
            position: relative;
        }

        span:last-child {
            padding-top: bu(1);
        }
    }

    &--alt2 {
        span:first-child {
            padding-bottom: 0;
            position: relative;
            z-index: 3;
        }

        span:last-child {
            padding-top: 0;
            position: relative;
            z-index: 3;
        }

        span:nth-child(3) {
            padding-top: bu(1);
            padding-bottom: bu(1);
        }
    }

    &--alt3 {
        span:first-child {
            padding-bottom: 0;
            position: relative;
            z-index: 3;
        }

        span:nth-child(3) {
            padding-top: bu(1);
        }
    }

    @include media($g-bp-x-small-and-up) {
        transform: scale(1);
    }

    @include media($g-bp-medium-and-up) {
        transform: scale(1.5);
        bottom: 10%;
    }

    @include media($g-bp-x-large-and-up) {
        transform: scale(2.1);
    }

    @include media($g-bp-xx-large-and-up) {
        transform: scale(2.5);
    }
}
.b-homepage-hero {
    background-position: right top;
    background-color: $g-color-white;
    height: 100vh;
    width: 100vw;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: $g-color-black;

    &__item {
        @include container();

        &__title {
            @include etc-font-family(900);
            margin-top: bu(16);
            margin-bottom: 0;
            color: $g-color-black;
            text-transform: uppercase;
            font-size: ms(19);
            line-height: bu(15);

            // This assumes there a a max of three lines of text in the hero
            span {
                position: relative;
                z-index: 2;
                background-color: $g-color-white;
                display: inline-block;
                padding: 0 bu(2);
            }

            span:first-child {
                position: static;
            }

            span:last-child {
                z-index: 1;
                top: bu(-4);
            }

            span:first-child,
            span:last-child {
                padding-top: bu(1.5);
                padding-bottom: bu(1.5);
            }

            // This includes the <br>
            span:nth-child(3) {
                padding-top: 0;
                top: bu(-2);
            }

            em {
                font-style: normal;
                color: $g-color-red;
            }
        }
    }

    @include media($g-bp-medium-large-and-up) {
        background-position: center top;
    }

    @include media($g-bp-x-large-and-up) {
        &__item {
            max-width: 100%;
            padding: 0 bu(12);
        }
    }

    // Hero images
    @include hero-bg('home');
}

.b-homepage-section {
    font-size: bu(2);
    transition: opacity 300ms ease;
    opacity: 0;
    width: 100%;

    .fp-completely & {
        transition: opacity 2s ease;
        opacity: 1;
    }

    &__title {
        @include etc-font-family(900);
        text-transform: uppercase;
        font-size: calc(100vw / 5);
        line-height: em(52, 66);
        margin: 0 0 rem(20);
    }

    &__text {
        letter-spacing: -.005em;
        font-size: em(18);
        line-height: em(24, 18);
        max-width: rem(560);
    }

    &__link {
        max-width: em(320);

        &:hover {
            background: $g-color-white;
            color: $g-color-black;

            .h-icon{
                color: $g-color-black;
            }
        }
    }

    &__link-primary {
        margin-bottom: em(6);
    }

    &__link-secondary {
        &-lg {
            display: none;

            span {
                width: bu(25.5);
                display: inline-block;
            }
        }
    }

    @include media($g-bp-small-and-up) {
        &__text {
            letter-spacing: -.03em;
            line-height: bu(4);
            font-size: ms(4);
            margin-bottom: bu(3.5);
        }
    }

    @include media($g-bp-medium-and-up) {
        padding: 0 4rem;

        &__link {
            max-width: em(320);
        }
    }

    @include media($g-bp-large-and-up) {
        padding-top: rem(68);
    }

    @media screen and (min-width: 1120px) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        transition: none;
        opacity: 1;

        &__title {
            font-size: calc(10vw);
        }

        &__link-secondary {
            display: none;

            &-lg {
                @include etc-font-family(800);
                display: block;
                text-transform: uppercase;
                line-height: rem(42);
                min-width: rem(300);
                width: rem(300);
                letter-spacing: -.01em;
                font-size: rem(50);
                position: relative;
                border: 4px solid $g-color-black;
                padding: bu(11) bu(4);
                height: bu(37.5);
                transition: opacity 300ms ease, transform 300ms ease;
                transform: translateX(60px);
                opacity: 0;

                .fp-completely & {
                    transform: translateX(0);
                    transition: opacity 1s ease, transform 333ms ease;
                    opacity: 1;
                }

                &-icon {
                    position: absolute;
                    bottom: 0;
                    left: bu(4);
                    width: 1.5em;
                    height: 1.2em;
                }

                &::after {
                    position: absolute;
                    bottom: bu(7.5);
                    left: bu(4);
                    height: 1px;
                    width: calc(100% - 8rem);
                    content: '';
                    background: $g-color-black;
                }

                &:hover {
                    background: $g-color-white;
                    color: $g-color-black;
                }
            }
        }
    }
}

.b-image,
%b-image {

    @include content-block();

    display: flex;
    flex-direction: column;
    width: 100%;

    &__image {
        width: 100%;
        margin-bottom: bu(2);
    }

    &__caption {
        padding-bottom: bu(2);
        border-bottom: 4px solid $g-color-black;
        line-height: bu(2);

        @include media($g-bp-medium-and-up) {
            font-size: ms(-1);
        }
    }

    &--float {
        @include media($g-bp-medium-and-up) {
            width: 37%;
            margin-bottom: bu(4);
            margin-top: 0;
        }
    }

    &--float-left {
        @include media($g-bp-medium-and-up) {
            float: left;
            margin-right: bu(4);
        }
    }

    &--float-right {
        @include media($g-bp-medium-and-up) {
            float: right;
            margin-left: bu(4);
        }
    }
}

.b-image-hero,
%b-image-hero {
    position: relative;
    background-position-x: 50%;
    background-color: $g-color-white;
    width: 100%;
    height: 123vw;
    background-repeat: no-repeat;
    background-size: cover;

    &__item {
        @include container();

        &__arrow {
            display: none;  // Todo
            position: relative;
            width: bu(7);
            height: bu(7);
            cursor: pointer;
            left: 55%;
        }
    }

    @include media($g-bp-medium-and-up) {
        height: 66vw;
    }

    @include media($g-bp-large-and-up) {
        height: 47vw;

        &--example {
            background-image: url("../images/hero/Guy-Hero—1920.png");
            background-color: $g-color-black;
            background-size: cover;
        }
    }

    @include media($g-bp-x-large-and-up) {
        &__item {
            max-width: 100%;
            padding: 0 bu(12);
        }
    }


    //
    // Hero images
    //

    &--quiz {
        // Hero images
        @include hero-bg('quiz');

        @include media($g-bp-large-and-up) {
            background-position-y: rem(54);
        }
    }

    &--facts {
        // Hero images
        @include hero-bg('facts');

        @include media($g-bp-large-and-up) {
            background-position-y: rem(36);
            background-position-x: right;
        }
    }

    &--vs {
        // Hero images
        @include hero-bg('vs');

        @include media($g-bp-large-and-up) {
            background-position-x: 33%;
        }
    }

    &--quit {
        // Hero images
        @include hero-bg('quit');

        @include media($g-bp-large-and-up) {
            background-position-y: rem(36);
            background-position-x: 65%;
        }
    }
}

.b-link,
%b-link {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    border-top: 4px solid $g-color-black;
    border-bottom: 4px solid $g-color-black;
    font-weight: bold;
    margin-top: bu(2);
    margin-bottom: bu(5);

    &__title {
        margin: .8em 0;
        line-height: bu(2.5);
        max-width: 90%;

        &--two {
            font-weight: normal;
            display: block;
            margin-top: bu(1);
        }
    }

    &:hover {
        background-color: transparent !important; // Important to override content link style.
        color: $g-color-red !important;
        border-color: $g-color-red;
    }

    svg {
        width: 3em;
    }
}

.b-link-block,
%b-link-block {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: bu(5) bu(3) bu(3);
    border: 6px solid $g-color-black;

    &__title {
        @include etc-font-family(700);
        margin-top: 0;
        margin-bottom: bu(4);
        font-size: ms(10);
        line-height: bu(5.5);
        color: $g-color-black;
        text-transform: uppercase;

        @include media($g-bp-medium-and-up) {
            font-size: ms(11);
            line-height: bu(6);
        }
    }

    &__content {
        font-size: ms(1);
        line-height: bu(3.5);
    }

    &__icon {
        width: 3em;
    }

    @include media($g-bp-medium-and-up) {
        padding: bu(5) bu(5) bu(3);
    }

    &:hover {
        background-color: $g-color-white;
        color: $g-color-black;
    }

    img {
        width: 100%;
        height: auto;
        vertical-align: top;
        display: block;
    }
}

.b-link-block__image {
    align-self: flex-start;
    margin: calc(5rem * -1);
    margin-bottom: bu(5);
    aspect-ratio: 16 / 9;
}


/*
	Improved screen reader only CSS class
	@author Gaël Poupard
		@note Based on Yahoo!'s technique
		@author Thierry Koblentz
		@see https://developer.yahoo.com/blogs/ydn/clip-hidden-content-better-accessibility-53456.html
	* 1.
		@note `clip` is deprecated but works everywhere
		@see https://developer.mozilla.org/en-US/docs/Web/CSS/clip
	* 2.
		@note `clip-path` is the future-proof version, but not very well supported yet
		@see https://developer.mozilla.org/en-US/docs/Web/CSS/clip-path
		@see http://caniuse.com/#search=clip-path
		@author Yvain Liechti
		@see https://twitter.com/ryuran78/status/778943389819604992
	* 3.
		@note preventing text to be condensed
		author J. Renée Beach
		@see https://medium.com/@jessebeach/beware-smushed-off-screen-accessible-text-5952a4c2cbfe
		@note Drupal 8 goes with word-wrap: normal instead
		@see https://www.drupal.org/node/2045151
		@see http://cgit.drupalcode.org/drupal/commit/?id=5b847ea
	* 4.
		@note !important is important
		@note Obviously you wanna hide something
		@author Harry Roberts
		@see https://csswizardry.com/2016/05/the-importance-of-important/
*/

.sr-only {
	border: 0 !important;
	clip: rect(1px, 1px, 1px, 1px) !important; /* 1 */
	-webkit-clip-path: inset(50%) !important;
		clip-path: inset(50%) !important;  /* 2 */
	height: 1px !important;
	margin: -1px !important;
	overflow: hidden !important;
	padding: 0 !important;
	position: absolute !important;
	width: 1px !important;
	white-space: nowrap !important;            /* 3 */
}

/*
	Use in conjunction with .sr-only to only display content when it's focused.
	@note Useful for skip links 
	@see http://www.w3.org/TR/2013/NOTE-WCAG20-TECHS-20130905/G1
	@note Based on a HTML5 Boilerplate technique, included in Bootstrap
	@note Fixed a bug with position: static on iOS 10.0.2 + VoiceOver
		@author Sylvain Pigeard
		@see https://github.com/twbs/bootstrap/issues/20732
*/
.sr-only-focusable:focus,
.sr-only-focusable:active {
	clip: auto !important;
	-webkit-clip-path: none !important;
		clip-path: none !important;
	height: auto !important;
	margin: auto !important;
	overflow: visible !important;
	width: auto !important;
	white-space: normal !important;
}
.b-list,
%b-list {
    margin-top: bu(2);
    margin-bottom: bu(2);

    ul,
    ol {
        padding-left: bu(3);
        margin-top: 0;
    }

    ul {
        list-style-type: "‒\00a0";
    }

    li {
        padding-left: bu(1);
        margin-bottom: bu(1);

        font-size: 2.25rem;
        line-height: 3.5rem;

        @include media($g-bp-large-and-up) {
            font-size: 2.53125rem;
            line-height: 3.5rem;
        }
    }

    &--no-top-margin {
        margin-top: 0;
    }
}

.b-logo {
    @include font-scale(2);
    @include etc-font-family(900);
    padding: 0;
    margin: rem(12) 0 0 rem(1);
    line-height: 1;
    text-transform: uppercase;

    &__link {
        border: 0;
    }

    span {
        line-height: .8;
        position: relative;
        z-index: 2;
        background-color: $g-color-white;
        display: inline-block;
        padding: 0 rem(3);

        &:first-child {
            padding-top: rem(2);
            padding-bottom: rem(2);
        }

        &:last-child {
            position: relative;
            top: rem(-4);
            padding-top: rem(1);
            padding-bottom: rem(1);
        }
    }

    @include media($g-bp-large-and-up) {
        @include font-scale(5);
        margin-top: bu(1);
        margin-left: 0;

        span {
            padding: 0 rem(3);

            &:first-child {
                padding-top: rem(2);
                padding-bottom: rem(2);
            }

            &:last-child {
                position: relative;
                top: rem(-10);
                padding-top: 0;
                padding-bottom: rem(2);
            }
        }
    }
}

.b-panel {
    border: 1px solid transparent;

    &__inner {
        @include container();
        position: relative;
    }
}

.b-plain-hero,
%b-plain-hero {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: bu(20) 0 bu(8);

    &__item {
        width: 100%;

        &--right {
            @include media($g-bp-large-and-up) {
                padding-left: bu(16);
            }
        }

        &__section {
            @include etc-font-family(700);
            background-color: $g-color-black;
            padding: bu(1) bu(1.5);
            color: $g-color-white;
            text-transform: uppercase;
            font-size: ms(3);
            border-bottom: none;

            &:hover {
                color: $g-color-black;
                background-color: $g-color-white;
            }
        }

        &__title {
            @include etc-font-family(900);
            display: block;
            text-transform: uppercase;
            font-size: ms(12);
            line-height: bu(7);
            margin-top: bu(4);
            margin-bottom: bu(3);

            @include media($g-bp-medium-and-up) {
                font-size: ms(17);
                line-height: bu(11.5);
            }

            @include media($g-bp-x-large-and-up) {
                font-size: 17.5rem;
                line-height: bu(13.5);
            }
        }

        &__intro {
            margin-top: bu(0);
            margin-bottom: bu(3);
            font-size: ms(2);
            line-height: bu(3.5);

            @include media($g-bp-medium-and-up) {
                font-size: ms(3);
                line-height: bu(4);
                margin-top: bu(3);
                margin-bottom: 0;
            }

            @include media($g-bp-large-and-up) {
                margin-top: bu(7);
                margin-bottom: bu(5);
            }
        }

        &__line {
            height: 4px;
            background-color: $g-color-black;
            width: 20%;
            display: none;

            @include media($g-bp-large-and-up) {
                display: block;
            }
        }

        @include media($g-bp-large-and-up) {
            width: 50%;
        }
    }

    @include media($g-bp-large-and-up) {
        margin: bu(22) 0 bu(6);
        flex-direction: row;
        justify-content: space-between;
    }
}

.b-quiz {
    width: 100%;
    padding: bu(5) 0;

    &__start {
        text-align: center;
        font-size: ms(4);

        &__content {
            line-height: bu(3.5);
            font-size: ms(2);

            p{
                margin: 0;
            }

            &__button__share {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin: bu(3) 0;

                @include media($g-bp-large-and-up) {
                    flex-direction: row;
                    height: 250px;
                    margin: 0;
                }
            }

            &__button {
                @include etc-font-family(900);
                display: inline-block;
                width: 100%;
                margin: bu(3) 0;
                border: 4px solid $g-color-black;
                background-color: $g-color-black;
                font-size: ms(12);
                line-height: bu(13);
                color: $g-color-white;
                text-transform: uppercase;
                cursor: pointer;

                @include media($g-bp-medium-and-up) {
                    width: 65%;
                    font-size: ms(15);
                    line-height: bu(15);
                }

                @include media($g-bp-large-and-up) {
                    width: 65%;
                    font-size: ms(16);
                    line-height: bu(19);
                    margin-left: bu(15);
                }

                &:hover {
                    background-color: $g-color-white;
                    color: $g-color-black;
                }
            }

            &__share {
                display: inline-block;

                &__icon {
                    cursor: pointer;
                    height: bu(6);
                    width: bu(6);

                    @include media($g-bp-large-and-up) {
                        height: bu(7);
                        width: bu(7);
                    }

                    &:hover {
                        color: $g-color-white;
                    }
                }

                &__platforms {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    padding: 0 bu(10);

                    &__icon {
                        height: bu(6);
                        width: bu(6);
                    }

                    a {
                        margin: 0 bu(2);

                        @include media($g-bp-large-and-up) {
                            margin: bu(1) 0;
                        }
                    }

                    a:hover {
                        color: $g-color-white;
                    }

                    @include media($g-bp-large-and-up) {
                        flex-direction: column;
                        padding: 0;
                    }
                }

                @include media($g-bp-large-and-up) {
                    margin-left: bu(8);
                    width: 60px;
                }
            }

            @include media($g-bp-medium-and-up) {
                line-height: bu(4);
                font-size: ms(4);
            }

            @include media($g-bp-large-and-up) {
                line-height: bu(5);
                font-size: ms(5);
            }
        }

        p > a {
            border-bottom: 1px solid $g-color-black;
        }

        p > a:hover {
            color: $g-color-white;
            border-bottom: 1px solid $g-color-white;
        }

        @include media($g-bp-large-and-up) {
            margin-top: bu(2);
        }
    }

    &__question {
        display: grid;
        grid-template-columns: 1fr;
        padding: bu(4) 0;

        &__details {
            display: flex;
            align-items: flex-start;
            flex-direction: column;

            &__number {
                @include etc-font-family(700);
                margin: 0;
                line-height: bu(2.5);
                padding: bu(1.5) bu(3);
                background-color: $g-color-black;
                font-size: ms(4);
                color: $g-color-white;
                text-transform: uppercase;
            }

            &__title {
                font-size: ms(4);
                line-height: bu(4);
                font-weight: bold;
                max-width: 100%;

                @include media($g-bp-medium-and-up) {
                    font-size: ms(8);
                    line-height: bu(6.5);
                    font-weight: 700;
                }

                @include media($g-bp-large-and-up) {
                    font-size: ms(7);
                    line-height: bu(6);
                    max-width: 85%;
                }

                @include media($g-bp-x-large-and-up) {
                    font-size: ms(10);
                    line-height: bu(8);
                }
            }
        }

        &__answer {
            margin-top: bu(2);

            &__option {
                @include etc-font-family(500);
                display: flex;
                align-items: center;
                text-align: left;
                flex-direction: row;
                border: 2px solid $g-color-black;
                margin-bottom: bu(2);
                font-size: ms(4);
                line-height: rem(20);
                text-transform: uppercase;
                cursor: pointer;
                width: 100%;
                padding: 0;
                background-color: transparent;

                &:hover {
                    background-color: $g-color-white;
                }

                span {
                    padding: bu(1) 0;
                }

                &__number {
                    @include etc-font-family(900);
                    display: inline-block;
                    padding: bu(1);
                    margin-right: bu(2);
                    background-color: $g-color-black;
                    font-size: ms(7);
                    line-height: bu(5);
                    color: $g-color-white;
                    min-width: bu(6);
                    text-align: center;

                    @include media($g-bp-large-and-up) {
                        padding: bu(0) bu(3.5) bu(0) bu(3);
                        margin-right: bu(3);
                        font-size: ms(10);
                        line-height: bu(7);
                        min-width: bu(10);
                    }
                }

                .start{
                    margin-right: bu(1);
                    font-variation-settings: $g-black-narrow;
                }

                @include media($g-bp-medium-and-up) {
                    border: 4px solid $g-color-black;
                    font-size: ms(6);
                    line-height: rem(26);
                }
            }

            @include media($g-bp-medium-and-up) {
                margin-top: bu(2);
            }

            @include media($g-bp-large-and-up) {
                margin-top: bu(11);
            }
        }

        &__result {
            margin-top: bu(2);
            margin-bottom: bu(3);
            grid-column: 1;

            &__header {
                @include etc-font-family(500);
                padding: bu(2) bu(3);
                background-color: $g-color-black;
                font-size: ms(4);
                color: $g-color-white;
                text-transform: uppercase;
                display: flex;
                flex-direction: row;
                align-items: center;

                &__icon {
                    width: bu(3);
                    height: bu(3);
                    margin-right: bu(2);

                    @include media($g-bp-large-and-up) {
                        width: bu(4);
                        height: bu(4);
                        margin-right: bu(5);
                    }
                }

                &__answer {
                    &--correct {
                        .start {
                            color: $g-color-green;
                            font-variation-settings: $g-black-narrow;
                        }
                    }

                    &--incorrect {
                        .start {
                            color: $g-color-red;
                            font-variation-settings: $g-black-narrow;
                        }
                    }
                }

                @include media($g-bp-large-and-up) {
                    padding: bu(3) bu(3);
                    font-size: ms(6);
                }
            }

            &__content {
                padding: bu(3) bu(2);
                border: 2px solid $g-color-black;
                background-color: $g-color-white;
                line-height: bu(3);

                &__title {
                    margin: 0;
                    font-weight: bold;
                    margin-bottom: bu(1);
                    line-height: bu(3.5);
                    font-size: ms(1);

                    @include media($g-bp-medium-and-up) {
                        line-height: bu(4);
                        font-size: ms(2);
                    }
                }

                &__fact {
                    margin-top: 0;
                    line-height: bu(3.5);
                    font-size: ms(1);

                    @include media($g-bp-medium-and-up) {
                        line-height: bu(4);
                        font-size: ms(2);
                    }
                }

                &__sources {
                    font-size: ms(1);
                }

                @include media($g-bp-large-and-up) {
                    padding: bu(3) bu(4);
                    border: 4px solid $g-color-black;
                }
            }

            &__next {
                @include etc-font-family(500);
                padding: bu(2);
                cursor: pointer;
                font-size: ms(6);
                margin-top: bu(4);
                width: 100%;

                @include media($g-bp-large-and-up) {
                    padding: bu(1.5) bu(3);
                }
            }

            @include media($g-bp-large-and-up) {
                grid-column: 2;
                margin-top: bu(11);
            }
        }

        @include media($g-bp-medium-and-up) {
            padding: bu(8) bu(4);

            // Only for browsers that support grid as height can vary when stacking in IE
            @supports (display: grid) {
                height: bu(80);
            }
        }

        @include media($g-bp-large-and-up) {
            grid-template-columns: 1fr 1fr;
        }
    }

    &__end {
        @include etc-font-family(700);
        display: grid;
        text-transform: uppercase;
        grid-template-columns: 1fr;
        padding: bu(4) bu(2);

        &__result {
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: flex-start;

            &__title {
                font-size: ms(7);
                white-space: nowrap;
            }

            &__message {
                margin-top: bu(0);
                margin-bottom: bu(0);
                font-size: ms(7);
                line-height: bu(2);

                @include media($g-bp-large-and-up) {
                    margin-top: bu(3);
                    margin-bottom: bu(1);
                    font-size: ms(12);
                    line-height: bu(3);
                }
            }

            &__score {
                font-size: ms(19);
                line-height: bu(22);
                font-variation-settings: $g-bold-condensed;

                &__number {
                    color: $g-color-white;
                }

                @include media($g-bp-large-and-up) {
                    font-size: ms(24);
                    line-height: bu(35);
                }
            }

            &__share {
                display: flex;
                align-items: center;
                flex-direction: row;
                justify-content: center;
                width: 100%;
                padding: bu(2);
                border: 4px solid $g-color-black;
                background-color: transparent;
                cursor: pointer;

                &__icon {
                    width: bu(3.5);
                    height: bu(3.5);
                    margin-right: bu(3);
                }

                &__label {
                    font-size: ms(4);

                    @include media($g-bp-large-and-up) {
                        font-size: ms(5);
                    }
                }

                &:hover {
                    background-color: $g-color-white;
                    color: $g-color-black;
                }

                &__platforms {
                    display: flex;
                    align-items: center;
                    flex-direction: row;
                    justify-content: space-evenly;
                    width: 100%;
                    padding: bu(1);
                    border: 4px solid $g-color-black;
                    background-color: $g-color-black;
                    color: $g-color-white;

                    &__icon {
                        width: bu(5);
                        height: bu(5);
                    }

                    a {
                        color: $g-color-white;
                        text-decoration: none;
                        padding: 0;
                        border: none;

                        &:hover {
                            color: $g-color-red;
                        }
                    }

                    @include media($g-bp-large-and-up) {
                        width: bu(62);
                        padding: bu(1) bu(10);
                    }
                }

                @include media($g-bp-large-and-up) {
                    width: bu(62);
                    padding: bu(2);
                }
            }
        }

        &__share {
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: flex-start;
            padding-top: bu(3);

            &__message {
                margin-bottom: bu(6);
                font-family: $g-font-primary;
                font-size: ms(3);
                line-height: bu(4);
                font-weight: $g-semi-bold;
                text-transform: none;

                @include media($g-bp-large-and-up) {
                    width: 90%;
                    font-size: ms(4);
                }
            }

            &__button {
                width: 100%;
                padding: bu(3);
                margin-bottom: bu(3);
                line-height: bu(3);

                @include media($g-bp-large-and-up) {
                    width: 90%;
                }
            }

            @include media($g-bp-large-and-up) {
                padding-top: bu(7);
            }
        }

        @include media($g-bp-medium-and-up) {
            padding: bu(8) bu(4);


            // Only for browsers that support grid as height can vary when stacking in IE
            @supports (display: grid) {
                height: bu(80);
            }
        }

        @include media($g-bp-large-and-up) {
            grid-template-columns: 1fr 1fr;
            grid-column-gap: bu(5);
        }
    }

    @include media($g-bp-large-and-up) {
        padding: bu(10) 0 bu(13);
    }
}

.b-quiz-panel {
    .b-panel__inner {
        margin: 0 auto;
    }
}

.b-quote,
%b-quote {
    display: flex;
    flex-direction: column;
    margin-left: 0;
    margin-top: bu(8);
    margin-bottom: bu(8);

    &__icon {
        margin-bottom: bu(3);
        height: bu(8);
        width: bu(8);
    }

    &__quote {
        font-size: ms(4) !important;
        line-height: bu(4) !important;
        font-family: $g-font-primary;
        font-weight: $g-font-weight-black;

        @include media($g-bp-medium-and-up) {
            line-height: bu(5) !important;
        }
    }

    &__citation {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        &__icon {
            margin-right: bu(3);
            width: bu(8);
        }

        a {
            border-bottom: none;
            font-size: 2.25rem !important;
            line-height: 3rem !important;
            border-bottom: 1px solid $g-color-black;
        }

        @include media($g-bp-medium-and-up) {
            flex-direction: row;
            align-items: center;
        }
    }

    @include media($g-bp-medium-and-up) {
        margin-top: bu(8);
        margin-bottom: bu(6);
    }
}

.b-skip-links {
    display: none;
}

.b-tabbed-page,
%b-tabbed-page {
    width: 100%;
    margin-top: bu(6);

    &__inner {
        @include container();
        position: relative;
        padding-bottom: bu(4) !important;
    }

    &__tabs {
        text-transform: uppercase;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        list-style-type: none;
        margin: 0;
        padding: 0;

        li {
            margin: 0 !important;
        }

        &__item {
            @include etc-font-family(700);
            text-transform: uppercase;
            border-bottom: none;
            text-align: center;
            cursor: pointer;
            font-size: ms(4);
            padding: bu(1);
            border-width: 4px;
            border-color: transparent;
            line-height: bu(3.5);
            margin: 0;
            background-color: transparent;
            margin-bottom: -4px;
            background: none;

            &--active {
                border: 4px solid $g-color-black;
                border-bottom: none;
                color: $g-color-red;
                font-variation-settings: $g-black-condensed;
                background-color: $g-color-white;

                @include media($g-bp-large-and-up) {
                    border: 6px solid $g-color-black;
                    border-bottom: none;

                }
            }

            @include media($g-bp-x-small-and-up) {
                font-size: 4.56139rem;
                padding: bu(2);
            }

            @include media($g-bp-medium-and-up) {
                font-size: ms(7);
                line-height: bu(7);
                padding: bu(2) 0;
                width: bu(20);
            }

            @include media($g-bp-large-and-up) {
                font-size: ms(10);
                line-height: bu(9);
                padding: bu(2) 0;
                border-width: 6px;
                width: bu(25);
                margin-bottom: -6px;
            }

            @include media($g-bp-x-large-and-up) {
                font-size: ms(12);
                line-height: bu(10);
                padding: bu(2.5) 0;
                border-width: 6px;
                width: bu(35);
            }
        }

        @include media($g-bp-large-and-up) {
            margin-top: bu(10);
        }
    }

    &__content {
        background-color: $g-color-white;
        border: 4px solid $g-color-black;
        padding: bu(4) bu(2);
        width: 99.99%;  // Needed so the content border matches heading border otherwise is 1px difference.

        @include media($g-bp-medium-and-up) {
            padding: bu(6) bu(4);
        }

        @include media($g-bp-large-and-up) {
            border: 6px solid $g-color-black;
            padding: bu(10) bu(8);
        }
    }
}

.text-changer {
    position: absolute;
    top: 100vh;
    // Uses custom css property generated by javascript for when mobile browser bar overlays viewport
    // See utils.js
    top: calc(var(--vh, 1vh) * 100 - 13vh);

    &__next-slide {
        background: transparent;
        border: 0;
        position: absolute;
        margin: auto;
        height: bu(8);
        cursor: pointer;
        left: calc(100vw - 10rem);
        bottom: rem(60);

        @include media($g-bp-x-small-and-up) {
            bottom: bu(10);
        }

        @include media($g-bp-medium-and-up) {
            left: calc(100vw - 13rem);
        }

        @include media($g-bp-large-and-up) {
            bottom: bu(18);
            left: calc(100vw - 18rem);
        }

        @include media($g-bp-x-large-and-up) {
            bottom: bu(21);
            left: calc(100vw - 35rem);
        }

        @include media($g-bp-xx-large-and-up) {
            bottom: bu(30);
            left: calc(100vw - 36rem);
        }

        &-icon {
            color: $g-color-white;
        }

        &:hover {
            * {
                color: $g-color-validation;
            }
        }
    }

    &__text {
        border: 0;
        padding: 0;

        > h1,
        > .h1 {
            margin: 0;

            > span {
                position: static;
            }
        }

        &:hover {
            .text-changer__cta-link {
                box-shadow: 0px 0px 0px 1px $g-color-white inset;
                border: 1px solid $g-color-white;
                color: $g-color-validation;
                background: $g-color-white;
            }
        }
    }

    &__group {
        pointer-events: none;
        position: absolute;
        bottom: 0;

        > * {
            transition: opacity 1s ease;
            opacity: 0;
        }

        .text-changer__cta {
            transition: opacity 1s ease;
            opacity: 0;
        }

        &.active {
            pointer-events: initial;

            > * {
                transition: opacity 1s ease 700ms;
                opacity: 1;
            }

            .text-changer__cta {
                transition: opacity 1s ease 700ms;
                opacity: 1;
            }
        }
    }

    &__cta {
        margin: rem(-4) 0 bu(.5);

        &-link {
            background-color: $g-color-validation;
            color: $g-color-white;
            padding: rem(3) bu(1);
            text-transform: uppercase;
            font-family: etc, Helvetica, Arial, sans-serif;
            font-variation-settings: $g-semi-bold-narrow;
            letter-spacing: 0;
            font-size: ms(0);
            border: 0;
        }

        &-arrow {
            width: bu(3.5);
            height: bu(2);
            margin-left: bu(1);
        }

        @include media($g-bp-x-small-and-up) {
            margin: rem(-6) 0 bu(.5);
        }

        @include media($g-bp-large-and-up) {
            transform: scale(1.5);
            transform-origin: bottom left;

            &-link {
                padding: rem(1) bu(1);
                font-size: rem(15);
            }

            &-arrow {
                height: rem(15);
                width: rem(22);
            }
        }

        @include media($g-bp-x-large-and-up) {
            transform: scale(1.8);
            margin-top: rem(-4);

            &-link {
                padding: rem(1) bu(1);
                font-size: rem(13);
            }

            &-arrow {
                height: rem(13);
                width: rem(20);
            }
        }

        @include media($g-bp-xx-large-and-up) {
            transform: scale(2.5);
            margin-top: rem(-2);

            &-link {
                padding: 0px 1rem 1px;
                font-size: 10px;
            }

            &-arrow {
                height: 11px;
                width: 18px;
            }
        }
    }

    @include media($g-bp-medium-and-up) {
        &__next-slide {
            right: bu(2);
        }
    }

    @include media($g-bp-large-and-up) {
        &__next-slide-icon {
            width: 2.5em;
            height: 2.5em;
        }
    }

    @include media($g-bp-x-large-and-up) {
        &__next-slide {
            right: bu(12);
        }

        &__next-slide-icon {
            width: 3em;
            height: 3em;
        }
    }

    @include media($g-bp-xx-large-and-up) {
        &__next-slide-icon {
            width: 3.5em;
            height: 3.5em;
        }
    }
}

.b-utility-links {
}

.b-video,
%b-video {
    @include container();
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: bu(7);

    &__title {
        display: flex;
        flex-direction: column;

        &__pre-title {
            p {
                margin-top: 0;
                margin-bottom: bu(2);
                font-size: ms(2);
                line-height: bu(4);

                @include media($g-bp-large-and-up) {
                    font-size: ms(3);
                    line-height: bu(4.5);
                }
            }
        }

        &__title {
            margin: 0;
            font-size: ms(2);
            line-height: bu(3.5);
            font-weight: bold;
            margin-bottom: bu(2);

            @include media($g-bp-large-and-up) {
                font-size: ms(3);
                line-height: bu(4);
                margin-bottom: bu(5);
            }
        }

        &__line {
            width: 25%;
            height: 4px;
            background-color: $g-color-black;
            display: none;

            @include media($g-bp-medium-and-up) {
                display: block;
            }
        }

        a {
            border-color: $g-color-black;

            &:hover {
                background-color: $g-color-black;
                color: $g-color-white;
                border: none;
            }
        }

        @include media($g-bp-medium-and-up) {
            width: 30%;
        }
    }

    &__content {
        margin-top: bu(5);

        &__caption {
            margin: 0;
            padding: bu(4) bu(3);
            background-color: $g-color-white;
            font-size: ms(0);

            @include media($g-bp-medium-and-up) {
                padding: bu(3);
            }
        }

        @include media($g-bp-medium-and-up) {
            width: 69%;
            margin-left: bu(2);
            margin-top: 0;
        }

        @include media($g-bp-large-and-up) {
            width: 65%;
            margin-left: bu(10);
        }
    }

    @include media($g-bp-medium-and-up) {
        flex-direction: row;
    }

    @include media($g-bp-large-and-up) {
        padding: 0 bu(8);
    }

    @include media($g-bp-x-large-and-up) {
        padding: 0 bu(12);
    }

    &--fifty-percent {
        .b-video__title {
            @include media($g-bp-medium-and-up) {
                width: 50%;
                padding-right: bu(5);
            }

            @include media($g-bp-large-and-up) {
                padding-right: bu(0);
            }

            @include media($g-bp-x-large-and-up) {
                padding-right: bu(10);
            }
        }

        .b-video__content {
            @include media($g-bp-medium-and-up) {
                width: 50%;
            }
        }

        .b-video__content__player {
            @include media($g-bp-medium-and-up) {
                height: 210px;
            }

            @include media($g-bp-large-and-up) {
                height: 320px;
            }
        }
    }

    &--detail-page {
        padding: 0 bu(2);

        .b-video__title__title {
            // Design wants video title size different on detail page to landing page.
            @include media($g-bp-large-and-up) {
                font-size: 3.6rem;
                line-height: 5rem;
            }
        }

        @include media($g-bp-medium-and-up) {
            padding: 0 bu(4);
        }

        @include media($g-bp-x-large-and-up) {
            padding: 0 bu(12);
        }
    }
}

.c-link-blocks,
%c-link-blocks {
    display: grid;
    align-items: start;
    padding: bu(3) 0;
    grid-template-columns: 1fr;
    grid-column-gap: bu(3);

    &__column {
        .b-link-block {
            margin-bottom: bu(3);
        }
    }

    @include media($g-bp-large-and-up) {
        padding: bu(12) 0;
        grid-template-columns: 1fr 1fr 1fr;
    }
}

// show hide menu

.alert {
  padding: 5px;
  background-color: #f44336;
  color: white;
  z-index: 10;
  text-align: center;
  a {
    text-decoration: underline;
  }
  a:hover {
    color: white;
  }
}

.closebtn {
  margin-left: 15px;
  color: white;
  font-weight: bold;
  //float: right;
  font-size: 22px;
  line-height: 20px;
  cursor: pointer;
  transition: 0.3s;

  &:hover, &:focus-visible {
    color: black;
  }
}

nav[role="navigation"]:is(.menu) {
  flex-direction: row;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 101;
}

// has js
.js {
  overflow-x: hidden;
  
  .menu {
    // overflow: hidden;
    li {
      letter-spacing: 0;
    }

    &__list {
      position: fixed; // Safari creates a scroll area when position: absolute
      right: -100%;
      bottom: 0;
      left: 100%;
      height: 100%;
      width: 100%;
      padding: bu(12) bu(1) bu(5);
      background: $g-color-black;
      overflow-y: auto;
      transform: translateX(0);
      transition: transform $g-speed ease;

      &-item {
        /*&:nth-child(1) {
          .menu__link {
            transition-delay: 300ms;
          }
        }
        &:nth-child(2) {
          .menu__link {
            transition-delay: 700ms;
          }
        }
        &:nth-child(3) {
          .menu__link {
            transition-delay: 1100ms;
          }
        }*/
      }

      .active {
        color: $g-color-red;
      }
    }

    &__link {
      transform: translateY(bu(0.5));
      opacity: 0;
      transition: opacity 1s 0ms, transform 1s 0ms;
    }

    &__button--toggle {
      margin-left: auto;
      display: flex;
      align-items: center;
      position: relative;
      z-index: 1;
    }

    &__button-label {
      display: none;

      &--active {
        display: none;
      }
    }
  }

  // menu is active
  &.menu-active {
    overflow: hidden;

    .menu {
      &__list {
        transform: translateX(-100%);
        transition: transform 500ms cubic-bezier(0.95, 0.05, 0.795, 0.035);
      }

      &__button-label {
        display: none;

        &--active {
          display: none;
        }
      }

      &__link {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }

  @include media($g-bp-x-large-and-up) {
    overflow-x: visible;

    .menu {
      align-items: center;

      &__list {
        display: flex;
        align-items: center;
        padding: 0;
        background-color: transparent;
        position: static;
        top: auto;
        right: auto;
        bottom: auto;
        width: auto;
        height: auto;
        transform: translateX(0);
        overflow-y: unset;
        column-gap: var(--gap);
        --gap: #{bu(2)};
        --fontSize: 22px;
        white-space: nowrap;
        font-size: var(--fontSize);

        @media (min-width: 1500px) {
          --gap: #{bu(3)};
          --fontSize: 25px;
        }


        &-item {
          //font-size: ms(3);
          //margin-right: bu(3);
          //padding-left: bu(3);
          font-size: inherit;
          position: relative;
          display: flex;
          align-items: center;
          column-gap: var(--gap);

          &::before {
            //position: absolute;
            content: "";
            left: 0;
            top: 3px;
            height: bu(3);
            border-left: 1px solid transparentize($g-color-white, 0.7);
          }

          &:first-child {
            &::before {
              display: none;
            }
          }

          &:nth-child(1) {
            .menu__link {
              transition-delay: unset;
            }
          }

          &:nth-child(2) {
            .menu__link {
              transition-delay: unset;
            }
          }

          &:nth-child(3) {
            .menu__link {
              transition-delay: unset;
            }
          }

          .menu__link {
            transform: unset;
            transition-delay: unset;
            transition: unset;
            opacity: 1;
            padding: 0;
          }
        }

        .b-utility-links,
        .b-sponsor-title {
          display: none;
        }
      }

      &__button--toggle {
        display: none;
      }
    }

    &.menu-active {
      .menu__list {
        transform: translateX(0);
      }
    }
  }
}

// default styles
.menu {
  background: transparent;

  @media (prefers-reduced-motion: no-preference) {
    transition: background 400ms ease;
  }

  .fp-scrolling &,
  .position-scrolled & {
    background: $g-color-black;
  }

  .fp-viewing-0 &,
  .fp-viewing-1 &,
  .fp-viewing-2 & {
    background: transparent;
  }

  @include media($g-bp-x-large-and-up) {
    background: $g-color-black;

    .fp-viewing-0 &,
    .fp-viewing-1 &,
    .fp-viewing-2 & {
      background: $g-color-black;
    }
  }

  &__inner {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    max-width: 100%;

    @include media($g-bp-large-and-up) {

      > .menu__list {
        max-width: calc(100vw - #{bu(12)} - 300px);
      }
    }

    @include media($g-bp-x-large-and-up) {
      padding: 0 bu(12);

      > .menu__list {
        max-width: calc(100vw - #{bu(12)} - 320px);
      }
    }
  }

  &__button--toggle {
    @include etc-font-family(500);
    display: none;
    padding: 0;
    margin: 0;
    color: $g-color-white;
    text-transform: uppercase;
    font-size: ms(3);

    .hamburger-box {
      margin-left: bu(0.5);
      transform: scale(0.7);
    }

    .hamburger-inner {
      background-color: $g-color-black;
      transition: background 700ms ease, transform 700ms ease, opacity 700ms ease;

      &::before,
      &::after {
        transition: background 700ms ease, transform 700ms ease, opacity 700ms ease;
        background-color: $g-color-black;
      }
    }

    .fp-scrolling &,
    .position-scrolled & {
      .hamburger-inner {
        background-color: $g-color-white;

        &::before,
        &::after {
          background-color: $g-color-white;
        }
      }
    }

    .fp-viewing-0 &,
    .fp-viewing-1 &,
    .fp-viewing-2 & {
      .hamburger-inner {
        background-color: $g-color-black;

        &::before,
        &::after {
          background-color: $g-color-black;
        }
      }
    }

    .menu-active & {
      .hamburger-inner {
        background-color: $g-color-white;

        &::before,
        &::after {
          background-color: $g-color-white;
        }
      }
    }
  }

  &__list {
    margin: 0;
    padding: 0;

    &-item {
      @include etc-font-family(900);
      list-style-type: none;
      margin: 0 bu(1.5);
      padding: bu(1) 0;
      text-transform: uppercase;
      font-size: ms(10);
      line-height: ms(8);
      border-bottom: 1px solid $g-color-validation;

      @include media($g-bp-x-large-and-up) {
        @include etc-font-family(500);
        font-size: ms(6);
        line-height: ms(6);
        border-bottom: 0;
        margin: 0;
        padding: 0;
      }
    }

    .b-utility-links__list {
      margin: 0;
      padding: bu(1) bu(1.5) bu(0);
      list-style-type: none;

      &-item {
        margin-bottom: bu(1);

        &__link {
          color: $g-color-white;
          font-weight: bold;
          font-size: ms(0);
          border: 0;
        }
      }
    }

    .b-sponsor-title {
      margin: 0 bu(1.5);
      border-top: 1px solid transparentize($g-color-white, 0.5);

      p {
        color: transparentize($g-color-white, 0.5);
        font-size: ms(-2);
        padding: 0;
      }
    }
  }

  &__link {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    border: 0;
    padding: 0;
    color: $g-color-white;

    &-icon {
      color: $g-color-white;
      width: bu(5.5);
      height: bu(2);
      margin-bottom: bu(1.5);
    }

    @include media($g-bp-large-and-up) {
      display: block;

      &-text {
        max-width: 100%;
      }

      &-icon {
        display: none;
      }
    }
  }

  &__logos {
    position: relative;
    min-height: bu(7);

    @include media($g-bp-large-and-up) {
      min-height: bu(8.5);
    }

    &--sponsors,
    &--vaping-facts {
      position: absolute;
      top: 0;
      left: 0;
      transition: transform $g-speed;
    }

    &--sponsors {
      display: flex;

      .h-icon {
        width: bu(9);
        height: bu(8);
        margin-right: bu(3);
        color: $g-color-white;

        @include media($g-bp-large-and-up) {
          width: 12rem;
          height: 8.5rem;
        }
      }
    }

    &--vaping-facts {
      transform: translateY(80px) translateX(20px) scale(1.8);

      @include media($g-bp-large-and-up) {
        transform: translateY(110px) translateX(30px) scale(1.8);
      }
    }
  }

  &--no-animation {
    .menu__logos {
      &--vaping-facts {
        transform: translateY(20px) translateX(20px) scale(1.8);

        @include media($g-bp-large-and-up) {
          transform: translateY(0) translateX(4px) scale(1.1);
        }

        .position-scrolled & {
          transform: translateY(0) translateX(8px) scale(1.3);

          @include media($g-bp-large-and-up) {
            transform: translateY(0) translateX(4px) scale(1.1);
          }
        }
      }
    }
  }

  // scrolling up or down shows either logo or sponsors in menu bar
  .fp-viewing-1 &,
  .fp-viewing-2 &,
  .fp-viewing-3 & {
    &__logos {
      &--sponsors {
        transform: translateY(-120px); //trinhlvd increase more to hide from 60px
      }

      &--vaping-facts {
        transform: translateY(0) translateX(8px) scale(1.3);

        @include media($g-bp-medium-and-up) {
          // transform: translateY(0) translateX(8px) scale(1.3);
        }

        @include media($g-bp-large-and-up) {
          transform: translateY(0) translateX(4px) scale(1.1);
        }
      }
    }
  }

  .fp-viewing-0 & {
    &__logos {
      &--vaping-facts {
        transform: translateY(80px) translateX(20px) scale(1.8);

        @include media($g-bp-large-and-up) {
          transform: translateY(110px) translateX(30px) scale(1.8);
        }
      }
    }

    &--no-animation {
      .menu__logos {
        &--vaping-facts {
          transform: translateY(20px) translateX(20px) scale(1.8);

          @include media($g-bp-large-and-up) {
            transform: translateY(0) translateX(4px) scale(1.1);
          }
        }
      }
    }
  }

  &--no-animation {
    .menu__logos {
      &--sponsors {
        transform: translateY(-120px); //trinhlvd added to remove other pages -60 to -120
      }
    }
  }
}

$g-color-brand: #f90;
$g-color-brand--alt: #ff335f;

$g-color-primary: #fb5547;
$g-color-secondary: #93e2e8;
$g-color-secondary--alt: #cdeaf7;
$g-color-tertiary: #3cc47d;


$g-color-black: #000;
$g-color-dark-gray: #222;
$g-color-light-gray: #f6f7f3;
$g-color-white: #fff;
$g-color-off-white: #f6f7f3;
$g-color-red: #fb5547;
$g-color-green: #3cc47d;
$g-color-blue: #cdeaf7;

// named colours
$g-color-focus-outline: $g-color-brand;
$g-color-validation: #f9594e;
$g-color-near-black: #1a1a1a;

// Global sizing
$g-font-base: 8px;
$g-line-height: bu(3);


// Modular Scale Typography
$ms-base: bu(2);
$ms-fluid: false;
$ms-ratio: $major-second;

$g-font-scale-sizing: (
        -5:  bu(.5),
        -4:  bu(1),
        -3:  bu(1.5),
        -2:  bu(2),
        -1:  bu(2.5),
        0:  bu(3),
        1:  bu(3.5),
        2:  bu(3.5),
        3:  bu(3.5),
        4:  bu(4),
        5:  bu(4.5),
        6:  bu(5),
        7:  bu(6),
        8:  bu(5.5),
        9:  bu(6),
        10:  bu(7),
        11:  bu(8),
        12:  bu(9),
        13:  bu(10),
        14:  bu(10.5),
        15:  bu(13),
        16:  bu(15)
);

@mixin font-scale($key) {
    @if map-has-key($g-font-scale-sizing, $key) {
        font-size: ms($key);
        line-height: map-get($g-font-scale-sizing, $key - 1);
    }
}

// Fonts
$g-font-primary: 'acumin-pro-wide', Arial, sans-serif;
$g-font-secondary: 'etc VF', Helvetica, Arial, sans-serif;
$g-font-tertiary: 'etc', Helvetica, Arial, sans-serif;

// Variable
@font-face {
    font-family: 'etc VF';
    font-display: auto;
    src: url('../fonts/ETCAnybodyVariable.woff2');
}

// Non variable
@font-face {
    font-family: 'etc';
    src: url('../fonts/ETCAnybody-BlackCondensed.woff2') format('woff2'),
        url('../fonts/ETCAnybody-BlackCondensed.woff') format('woff');
    font-weight: 900;
}

@font-face {
    font-family: 'etc';
    src: url('../fonts/ETCAnybody-BlackNarrow.woff2') format('woff2'),
        url('../fonts/ETCAnybody-BlackNarrow.woff') format('woff');
    font-weight: 800;
}

@font-face {
    font-family: 'etc';
    src: url('../fonts/ETCAnybody-BoldNarrow.woff2') format('woff2'),
        url('../fonts/ETCAnybody-BoldNarrow.woff') format('woff');
    font-weight: 700;
}

@font-face {
    font-family: 'etc';
    src: url('../fonts/ETCAnybody-BoldCondensed.woff2') format('woff2'),
        url('../fonts/ETCAnybody-BoldCondensed.woff') format('woff');
    font-weight: 600;
}

@font-face {
    font-family: 'etc';
    src: url('../fonts/ETCAnybody-SemiBoldNarrow.woff2') format('woff2'),
        url('../fonts/ETCAnybody-SemiBoldNarrow.woff') format('woff');
    font-weight: 500;
}

// Acumin settings
$g-semi-bold: 600;
$g-font-weight-black: 800;

// Variant fonts
$g-black-condensed: "wght" 900, "wdth" 10;
$g-black-narrow: "wght" 900, "wdth" 40;
$g-bold-narrow: "wght" 700, "wdth" 40;
$g-semi-bold-narrow: "wght" 550, "wdth" 60;
$g-bold-condensed: "wght" 600, "wdth" 20;

@function new-breakpoint($type, $value, $value2: false) {
    $query: false;
    @if $value2 {
        $value2: ($value2);
        $query: $type + '-width:' + $value + ') and (max-width:' + $value2;
    } @else {
        @if (#{$type}==max) {
            $value: ($value);
            $query: $type + '-width:' + $value;
        } @else {
            $query: $type + '-width:' + $value;
        }
    }
    @return $query;
}

@mixin media($query) {
    @media screen and ($query) {
        @content;
    }
}

.transition-fade {
    height: 100%;
    opacity: 1;
    transition: $g-speed opacity ($g-speed * 2);

    .nav {
        z-index: 900;
        width: 100%;
        transform: translateY(0);
        transition: $g-speed transform;
    }

    ~ #fp-nav {
        opacity: 1;
        transition: $g-speed opacity ($g-speed * 2);
    }
}

.is-animating .transition-fade {
    opacity: 0;

    .nav {
        transform: translateY(-100%);
    }

    ~ #fp-nav {
        opacity: 0;
    }
}

// transition out - default state
.translate-out {
    &-left {
        &--100pc {
            &--500ms {
                transform: translateX(0);
                transition: transform ($g-speed * 2);
            }
        }
    }
}

// transition out - transitioning
.is-leaving {
    .translate-out-left--100pc--500ms {
        transform: translateX(-100vw);
    }
}

// transition in - default state
.translate-in-left--100pc--500ms {
    transform: translateX(0);
    transition: transform ($g-speed * 2);
}

// transition in - transitioning
.is-rendering {
    .translate-in-left--100pc--500ms {
        transform: translateX(-100vw);
    }
}


.fp-next-slide {
    transition: opacity $g-speed;
    z-index: 100;
    position: fixed;
    bottom: rem(20);
    left: 0;
    right: 0;
    margin: auto;
    opacity: 1;
    border-radius: 50%;
    border: 0;
    width: bu(4.5);
    height: bu(4.5);
    background-color: $g-color-white;
    cursor: pointer;

    &.h-fade-out {
        opacity: 0;
    }

    .h-icon {
        height: bu(2.5);
        color: $g-color-black;
        vertical-align: middle;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        margin: auto;
    }

    @include media($g-bp-large-and-up) {

        .h-icon {
            right: auto;
            left: rem(-2);
        }
    }
}

.fp-section__inner {
    height: 100vh;
    // Uses custom css property generated by javascript for when mobile browser bar overlays viewport
    // See utils.js
    height: calc(var(--vh, 1vh) * 100 ) !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

.iScrollVerticalScrollbar {
    visibility: hidden;
}

.fp-section {
    &.fp-auto-height {
        .fp-tableCell {
            display: block;
        }
    }

    &__inner {
        margin: 0 auto;
        width: 100%;
        max-width: 162rem;
        min-width: 40rem;
        padding: 0 2rem;
    }


    @include media($g-bp-large-and-up) {
        &__inner {
            max-width: 100%;
            margin: 0 auto;
            width: 100%;
            min-width: 40rem;
            padding: 0 10rem;
        }
    }

    @include media($g-bp-x-large-and-up) {
        &__inner {
            padding: 0 bu(16);
        }
    }

    @include media($g-bp-xx-large-and-up) {
        &__inner {
            // padding: 0 bu(20);
            padding: 0;
            max-width: 1543px;
        }
    }
}

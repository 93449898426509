.h {
    &-block {
        display: block;
    }

    &-inline-block {
        display: inline-block;
    }

    &-inline-flex {
        display: inline-flex;
    }

    &-flex {
        display: flex;
    }

    &-grid {
        display: grid;
    }

    &-hidden {
        display: none;
    }
}

html {
    font-size: $g-font-base;
}

body {
    @include font-scale(0);
    font-weight: 400;

    @include media($g-bp-large-and-up) {
        @include font-scale(2);
    }
}

html,
body {
    padding: 0;
    margin: 0;

    * {
        box-sizing: border-box;
    }
}

ul {
    list-style-type: "‒\00a0";
}
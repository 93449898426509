$g-gutter: 40px;

@mixin grid-row($justify:'space-between') {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: #{$justify};
}

@mixin span-column($frac, $gutter:false, $flex-start:false, $nth: 2) {
    $current-gutter: $g-gutter;

    @if($gutter) {
        $current-gutter: $gutter;
    }

    flex-basis: calc(100% * #{$frac} - #{$current-gutter} * (1 - #{$frac}));
    flex-grow: 1;
    width: 100%;
    max-width: calc(100% * #{$frac} - #{$current-gutter} * (1 - #{$frac}));
    margin-bottom: calc(#{$g-gutter} / 2);

    @include media($g-bp-medium-and-up) {
        margin-bottom: $g-gutter;
    }

    @if($flex-start) {
        margin-right: $current-gutter;

        &:nth-child(odd),
        &:nth-child(even) {
            margin-right: $current-gutter;
        }

        &:nth-child(#{$nth}n) {
            margin-right: 0;
        }

        &:last-child {
            margin-right: 0;
        }
    }
}

// Intended to be applied to paragraphs in content page where standard margin is needed between it and the next component.
.h-margin-bottom-standard {
    margin-bottom: bu(5);
}

.h-margin-bottom-more {
    margin-bottom: bu(8);
}

// For use on the content or landing pages to give consistent top and bottom padding to the whole area containing
// the video and other content after the hero area.
.h-content-padding {
    padding: bu(8) 0;

    @include media($g-bp-large-and-up) {
        padding: bu(12) 0;
    }
}

// Top only - use on landing pages were there needs to be padding before the video but not after the landing links.
.h-content-padding--top {
    padding-top: bu(8);

    @include media($g-bp-large-and-up) {
        padding-top: bu(12);
    }
}

.h-last-content-item {
    margin-bottom: 0;
}

// Used for landing page titles.
.h-padding-top-bottom-none {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    margin-top: -40px;
    margin-bottom: -40px;
}

@mixin container($gutter: bu(4)) {
    margin: 0 auto;
    padding: 0 $gutter / 2;
    width: 100%;
    //max-width: rem($g-width--maximum);
    max-width: rem($g-bu * 209);
    min-width: rem($g-width--minimum);

    @include media($g-bp-medium-and-up) {
        padding: 0 bu(8);
    }

    @include media($g-bp-x-large-and-up) {
        padding: 0 bu(12);
    }
}


.l-container {
    @include container();
}

// Class for the main page content div.
.content-container {
    background-color: $g-color-off-white;
}
